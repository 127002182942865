@import "styles/vars"

.u-bound
	margin-left: auto
	margin-right: auto
	max-width: 1340px

ul.u-list
	list-style: none
	+childSpaceBottom(16px)

	li
		&:before
			content: "/"
			margin-right: 16px

.u-card
	margin-right: 34px
	background: $colorGrey
	border-radius: 24px
	padding: 16px 50px 32px 32px
	display: flex
	flex-direction: column
	align-items: flex-start
	justify-content: space-between
	+transition(box-shadow)
	+max($mTabletB)
		width: calc(100% - 34px)
	+max($mMobileL)
		padding: 24px
		padding-right: 27px

	&.align-top
		justify-content: flex-start

	&:hover
		box-shadow: -10px -10px 0 $colorMain
		+max($mTabletB)
			box-shadow: none

	img
		width: 100px
		height: 100px

	div.text-content
		margin-top: 36px
		h3
			font-size: 36px
			line-height: 48px
			letter-spacing: -1.08px
			+max($mMobileL)
				font-size: 22px
				line-height: 130%
				letter-spacing: -0.66px
				padding-right: 20px

		p
			margin-top: 18px
			+max($mMobileL)
				margin-top: 16px