@import "styles/vars"

section.c-projects
	padding: 116px 0 96px 0
	position: relative
	+max($mMobileL)
		padding: 72px 0
	&:before
		content: ""
		z-index: -1
		background: $colorGrey
		+pos(absolute, 0, -50px, -50px, 0)
		+max($mMobileL)
			left: -16px
			right: -16px
		
	header
		display: flex
		align-items: center
		justify-content: space-between
		margin-bottom: 65px
		+max($mMobileL)
			flex-direction: column
			align-items: flex-start
			margin-bottom: 32px
		h2
			margin-right: 36px
			min-width: 0
			+max($mMobileL)
				margin-right: 0
				margin-bottom: 24px
		div.c-generic-button
			flex-grow: 0
			flex-shrink: 0

	div.carouselle-controls
		margin-top: 48px
		display: flex
		align-items: center
		justify-content: center
		+childSpaceRight(32px)
		img
			cursor: pointer
			+transition(transform)
			&:first-child
				&:hover
					transform: translateX(-5px)
			&:last-child
				&:hover
					transform: translateX(5px)

	div.projects-list
		overflow: hidden
		div.pl-inner-wrapper
			display: flex
			align-items: stretch
			justify-content: flex-start
			margin-right: -32px
			transform: translateX(calc(-100% / var(--page) * var(--skip)))
			+transition(transform)
			transition-duration: 480ms
			+childSpaceRight(32px)
			
			a.pl-card
				width: calc(100% / var(--page) - 32px)
				height: 528px
				padding: 32px
				position: relative
				overflow: hidden
				position: relative
				background: white
				flex-grow: 0
				flex-shrink: 0
				border-radius: 24px
				+transition(background)

				+max($mTabletB)
					height: auto

				+max($mMobileL)
					padding: 24px

				+min($mTabletB)
					&:hover
						background: $colorMain
						div.badges
							opacity: 0

						div.content
							transform: translateZ(0)
							color: white
							div.image-wrapper
								img
									width: 100%

				div.badges
					+pos(absolute, 32px, 32px, 32px)
					display: flex
					align-items: flex-start
					justify-content: space-between
					+transition(opacity)
					+max($mMobileL)
						top: 24px
						left: 24px
						right: 24px

					div.c-fancy-button
						font-size: 16px
						flex-grow: 0
						flex-shrink: 1
						min-width: 0
						+max($mMobileL)
							height: 33px
							padding: 0 16px
							font-size: 12px
						span.content
							flex-grow: 1
							flex-shrink: 1
							+toe

					span.arrow
						width: 56px
						height: 56px
						display: flex
						align-items: center
						justify-content: center
						border-radius: 50%
						background: $colorMain
						flex-grow: 0
						flex-shrink: 0
						margin-left: 20px
						+max($mMobileL)
							width: 48px
							height: 48px
						img
							width: 36px
							height: 36px
							+max($mMobileL)
								width: 30px
								height: 30px

				div.content
					position: relative
					transform: translateY(172px) translateZ(0)
					+transition(color, transform)
					+max($mTabletB)
						transform: translateY(120px) translateZ(0)
						padding-bottom: 120px
					+max($mMobileL)
						transform: translateY(100px) translateZ(0)
						padding-bottom: 100px
					div.image-wrapper
						display: flex
						align-items: flex-start
						justify-content: flex-end
						margin-bottom: 36px
						img
							background-color: $colorGrey
							height: 184px
							width: 55%
							will-change: width
							transform: translateZ(0)
							+transition(width)
							border-radius: 16px

					h3
						height: 72px
						font-size: 32px
						line-height: 36px
						margin-bottom: 16px
						+max($mMobileL)
							font-size: 30px
							line-height: 130%
							height: auto

					p
						color: white
						+max($mTabletB)
							color: $colorDark
