@import "styles/vars"

section.ap-info-cards
	padding: 116px 0
	+max($mMobileL)
		padding: 56px 0

	div.inner-wrapper
		padding: 0 50px
		display: flex
		align-items: stretch
		justify-content: space-between
		+childSpaceRight(30px)
		+max($mTabletA)
			padding: 0
		+max($mMobileL)
			display: block
			+childSpaceRight(0)
			+childSpaceBottom(24px)

	div.card
		background: $colorGrey
		border-radius: 24px
		padding: 16px 32px 32px 32px
		+transition(box-shadow)
		+max($mMobileL)
			padding: 24px 27px 32px 24px

		&:hover
			box-shadow: -10px -10px 0 $colorMain
			+max($mTabletB)
				box-shadow: none

		img
			width: 100px
			height: 100px
			margin-bottom: 36px

		h2
			font-size: 36px
			line-height: 48px
			letter-spacing: -1.08px
			margin-bottom: 20px
			+max($mMobileL)
				font-size: 22px
				line-height: 130%
				letter-spacing: -0.66px
				margin-bottom: 16px