@import "styles/vars"

header.c-header
	height: 105px
	display: flex
	align-items: center
	justify-content: space-between
	border-bottom: 1px solid rgba($colorMain, .38)
	+max($mTabletA)
		+pos(fixed, 0, 0, 0)
		z-index: 1000
		height: 72px
		border-bottom: none
		padding: 0 50px
		padding-top: 24px
		display: block
		background: white
		overflow: hidden
		+transition(height)
		will-change: height
		transform: translateZ(0)

		&.expanded
			height: 472px

			div.burger
				span.lines
					background: transparent

					&:before
						transform: translateY(5px) rotate(-45deg)

					&:after
						transform: translateY(-5px) rotate(45deg)

	+max($mMobileL)
		padding-left: 16px
		padding-right: 16px

		&.expanded
			height: 100%

	img.logo
		height: 30px
		+max($mTabletA)
			height: 24px
			margin-bottom: 24px

	div.links
		+childSpaceRight(45px)
		+max($mTabletA)
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center
			padding: 24px 0
			margin-bottom: 24px
			+childSpaceBottom(24px)

		> *
			+transition(color)
			cursor: pointer
			position: relative
			+max($mTabletA)
				margin-right: 0

			&:before
				content: ""
				+pos(absolute, -41px, 0, 0)
				height: 6px
				background: $colorMain
				+transition(transform)
				transform: scaleY(0) translateZ(0)
				transform-origin: 50% 0%
				+max($mTabletA)
					content: none

			&:not(.disabled):hover
				color: $colorMain

			&.active
				color: $colorMain
				font-weight: 500
				pointer-events: none
				&:before
					transform: scaleY(1) translateZ(0)
			
			&.disabled
				opacity: .38
				cursor: not-allowed
				filter: blur(1px)

	div.cta
		+max($mTabletA)
			display: flex
			align-items: center
			justify-content: center

	div.burger
		display: none
		+pos(absolute, 24px, auto, 50px)
		width: 24px
		height: 24px
		align-items: center
		justify-content: center
		+max($mTabletA)
			display: flex
		+max($mMobileL)
			right: 16px

		span.lines
			$background: linear-gradient(to right, $colorMain, $colorAccent)
			width: 18px
			height: 2px
			position: relative
			background: $background
			+transition(background)

			&:before, &:after
				content: ""
				position: absolute
				left: 0
				right: 0
				height: 2px
				background: $background
				+transition(transform)

			&:before
				top: -5px

			&:after
				bottom: -5px