@import "styles/vars"

section.c-faq
	padding-top: 116px
	+max($mMobileL)
		padding-top: 72px
	
	div.inner-wrapper
		padding: 0 50px
		+max($mTabletA)
			padding: 0

	div.content
		margin-top: 54px
		display: flex
		align-items: flex-start
		justify-content: space-between
		+max($mTabletB)
			display: block
		+max($mMobileL)
			margin-top: 32px

		h2
			max-width: 375px
			margin-right: 32px
			flex-shrink: 0
			+max($mTabletA)
				flex-shrink: 1
			+max($mTabletB)
				max-width: 100%
				margin-right: 0
				margin-bottom: 48px

		div.accordions
			+childSpaceBottom(24px)
			width: 630px
			flex-shrink: 0
			+max($mTabletB)
				width: 100%
			+max($mMobileL)
				+childSpaceBottom(20px)