$fontMain: "Haas Grotesk Text", sans-serif

$transition: cubic-bezier(.23,1,.32,1)
$tjelly: cubic-bezier(.55,.17,.39,1.21)

$transitionTime: 250ms

$colorMain: #3A4CF5
$colorAccent: #7EA0F7
$colorDark: #1F2022
$colorBackground: #ffffff
$colorGrey: #f2f3f6

$colorPositive: #19C98A
$colorWarning: #FFC107
$colorDanger: #FF8D07
$colorNegative: #F44336

$mDesktopS: 1300px
$mTabletA: 1100px
$mTabletB: 800px
$mMobileL: 600px
$mMobileM: 480px
$mMobileS: 320px

=singleRunAnimation($name, $duration: $transitionTime, $timingFunction: $transition)
	animation-name: $name
	animation-duration: $duration
	animation-timing-function: $timingFunction
	animation-fill-mode: both
	animation-iteration-count: 1

=toe
	text-overflow: ellipsis
	white-space: nowrap
	overflow: hidden

=pos($position, $top: auto, $left: auto, $right: auto, $bottom: auto)
	position: $position
	top: $top
	left: $left
	right: $right
	bottom: $bottom

=placeholder
	&::-webkit-input-placeholder
		@content
	&::-webkit-placeholder
		@content
	&::-ms-input-placeholder
		@content
	&::-moz-placeholder
		@content
	&::placeholder
		@content

=scrollbar($color: $colorAccent, $at: .38, $bt: .72)
	&::-webkit-scrollbar
		width: 8px
		height: 8px
	&::-webkit-scrollbar-track
		background: rgba($color, $at)
	&::-webkit-scrollbar-thumb
		background: rgba($color, $bt)
		&:hover
			background: rgba($color, 1)

=transition($transitionProperties...)
	@if length($transitionProperties) == 0
		transition-property: all
	@else
		transition-property: $transitionProperties

	transition-duration: $transitionTime
	transition-timing-function: $transition	

=max($size)
	@media (max-width: $size)
		@content

=min($size)
	@media (min-width: $size + 1)
		@content

=childSpaceTop($margin)
	> *
		margin-top: $margin
		&:first-child
			margin-top: 0
			
=childSpaceLeft($margin)
	> *
		margin-left: $margin
		&:first-child
			margin-left: 0

=childSpaceRight($margin)
	> *
		margin-right: $margin
		&:last-child
			margin-right: 0

=childSpaceBottom($margin)
	> *
		margin-bottom: $margin
		&:last-child
			margin-bottom: 0