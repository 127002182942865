@import "styles/vars"

section.c-contacts
	padding: 116px 0px
	position: relative
	color: white
	+max($mMobileL)
		padding: 56px 0

	&:before
		content: ""
		background-color: $colorDark
		background-image: url("/static/images/topographic.svg")
		background-size: cover
		background-position: center
		z-index: -1
		+pos(absolute, 0, -50px, -50px, 0)
		+max($mMobileL)
			left: -16px
			right: -16px

	div.inner-wrapper
		padding: 0 50px
		+max($mTabletA)
			padding: 0

	h2
		margin-bottom: 56px
		span
			color: $colorMain
		+max($mMobileL)
			margin-bottom: 48px

	div.links
		display: flex
		align-items: center
		justify-content: space-between
		+max($mTabletB)
			flex-direction: column
			align-items: flex-start

		a.email
			font-size: 64px
			line-height: 70px
			letter-spacing: -1.92px
			border-bottom: 1px solid transparent
			+transition(color, border-color)
			&:hover
				border-color: $colorMain
				color: $colorMain
			+max($mMobileL)
				font-size: 52px
				line-height: 120%
				letter-spacing: -1.68px

		div.socials
			flex-grow: 1
			flex-shrink: 1
			max-width: 600px
			margin-left: 36px
			+max($mTabletB)
				margin-left: 0
				margin-top: 36px
				width: 100%
			+max($mMobileL)
				margin-top: 56px
			> p
				margin-bottom: 16px
				font-size: 20px
				line-height: 28px
				+max($mMobileL)
					font-size: 14px
					line-height: 120%

			div.list
				+childSpaceRight(16px)
				display: flex
				align-items: center
				justify-content: space-between
				a
					width: 100%
					flex-grow: 1
					flex-shrink: 1
					height: 50px
					border-radius: 25px
					box-shadow: inset 0 0 0 1px white
					display: flex
					align-items: center
					justify-content: center
					+transition(background, box-shadow)
					text-transform: capitalize
					&:hover
						box-shadow: inset 0 0 0 1px $colorMain
						background: $colorMain