@import "styles/vars"

section.hp-solutions
	margin-top: 116px
	color: white
	position: relative
	padding: 116px 0px
	+max($mMobileL)
		padding: 56px 0px
	&:before
		content: ""
		+pos(absolute, 0, -50px, -50px, 0)
		z-index: -1
		background: $colorMain
		background-image: url("/static/images/topographic.svg")
		background-size: cover
		background-position: center
		+max($mMobileL)
			left: -16px
			right: -16px

	div.inner-wrapper
		padding: 0 50px
		+max($mTabletA)
			padding: 0

	header
		padding-bottom: 48px
		margin-bottom: 48px
		border-bottom: 1px solid rgba(white, .24)
		display: flex
		align-items: flex-end
		justify-content: flex-start
		+max($mTabletA)
			display: block
		+max($mMobileL)
			padding-bottom: 32px
			margin-bottom: 32px
		> *
			flex-grow: 0
			flex-shrink: 0

		h2
			width: 590px
			margin-right: 50px
			+max($mTabletA)
				width: 100%
				margin-right: 0
				margin-bottom: 36px
			+max($mMobileL)
				margin-bottom: 16px

		p
			width: 420px
			+max($mMobileL)
				width: 100%

	div.content
		display: flex
		align-items: flex-start
		justify-content: flex-start
		+max($mTabletA)
			display: block

		div.categories
			width: 590px
			margin-right: 50px
			flex-grow: 0
			display: flex
			flex-wrap: wrap
			align-items: flex-start
			+childSpaceRight(8px)
			+childSpaceBottom(12px)
			+max($mTabletA)
				margin-right: 0
				margin-bottom: 36px
				width: 100%
			+max($mMobileL)
				justify-content: center
				margin-bottom: 24px
				+childSpaceBottom(6px)

		div.desktop-list
			+max($mMobileL)
				display: none

		div.mobile-list
			display: none
			+max($mMobileL)
				display: block
				width: 100%
				
		div.solutions-list-wrapper
			padding-top: 18px
			padding-bottom: 6px

		// ul
		// 	height: 352px
		// 	margin-bottom: -16px
		// 	list-style: none
		// 	display: flex
		// 	align-items: flex-start
		// 	justify-content: flex-start
		// 	flex-wrap: wrap
		// 	flex-grow: 1
		// 	flex-shrink: 1
		// 	width: 605px
		// 	+max($mTabletA)
		// 		width: 100%
		// 		max-width: 605px
		// 	li
		// 		margin-bottom: 16px
		// 		margin-right: 0
		// 		min-width: 40%
		// 		// flex-shrink: 0
		// 		&:before
		// 			content: "/ "
		// 			margin-right: 10px
		// 		+max($mMobileL)
		// 			font-size: 12px
		// 			width: 50%
		// 			flex-grow: 0
		// 			flex-shrink: 0