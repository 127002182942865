@import "styles/vars"

html, body
	position: relative
	height: 100%

body
	margin: 0
	padding: 0
	font-family: $fontMain
	font-size: 18px
	font-weight: 400
	line-height: 28px
	color: $colorDark
	background: $colorBackground
	padding-left: 50px
	padding-right: 50px
	+max($mTabletA)
		padding-top: 60px
	+max($mMobileL)
		padding-left: 16px
		padding-right: 16px
		font-size: 14px
		line-height: 150%
	*
		color: inherit
		font-size: inherit
		font-family: inherit
		line-height: inherit
		font-weight: inherit
		-webkit-font-smoothing: antialiased
		-moz-osx-font-smoothing: grayscale
		font-smooth: never
		margin: 0
		padding: 0
		appearance: none
		background: transparent
		border-radius: 0
		box-sizing: border-box
		border: none
		outline: none
		text-decoration: none
		&:invalid
			box-shadow: none
		&:before, &:after
			box-sizing: border-box

img
	object-fit: cover
	object-position: center
	display: block

h1
	&:not(.h2-like)
		font-size: 72px
		line-height: 120%
		letter-spacing: -2.16px
		+max($mTabletB)
			font-size: 54px
		+max($mMobileL)
			font-size: 30px
			line-height: 120%
			letter-spacing: -0.9px

h1.h2-like, h2
	font-size: 64px
	line-height: 120%
	letter-spacing: -1.92px
	+max($mTabletB)
		font-size: 48px
	+max($mMobileL)
		font-size: 30px
		letter-spacing: -0.9px