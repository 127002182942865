@import "styles/vars"

section.ap-image
	height: 400px
	position: relative
	+max($mTabletA)
		height: 300
	+max($mMobileL)
		height: 140px
	&:before
		content: ""
		+pos(absolute, 0, -50px, -50px, 0)
		background-color: $colorGrey
		background-image: url("/static/images/about_bg.png")
		background-size: cover
		background-position: center
		+max($mMobileL)
			left: -16px
			right: -16px