@import "styles/vars"

div.c-generic-modal
	display: flex
	align-items: center
	justify-content: center
	+pos(fixed, 0, 0, 0, 0)
	background: rgba($colorDark, .70)
	z-index: 2000
	padding: 50px
	+max($mMobileL)
		padding: 16px

	&.fade-in
		+singleRunAnimation(fade-in)
		div.gm-inner-wrapper
			+singleRunAnimation(scale-in)

	&.fade-out
		+singleRunAnimation(fade-out)
		div.gm-inner-wrapper
			+singleRunAnimation(scale-out)

	div.gm-inner-wrapper
		position: relative
		+max($mMobileL)
			width: 100%

		span.gm-close
			+pos(absolute, 0, auto, -30px)
			cursor: pointer
			+max($mMobileL)
				top: auto
				left: 0
				right: 0
				bottom: -48px
				display: flex
				align-items: center
				justify-content: center

			i
				width: 24px
				height: 24px
				display: flex
				align-items: center
				justify-content: center
				color: white
				font-size: 20px
