@import "styles/vars"

div.c-delimiter
	border-bottom: 1px solid $colorDark
	padding-bottom: 24px
	+max($mMobileL)
		padding-bottom: 16px

	&.light
		border-bottom-color: white
		
		p
			color: white

	p
		font-size: 20px
		line-height: 28px
		+max($mMobileL)
			font-size: 14px
			line-height: 28px