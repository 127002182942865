@import "styles/vars"

section.hp-outsourcing
	margin-top: 116px
	+max($mMobileL)
		margin-top: 72px

	h2
		margin-bottom: 56px
		+max($mMobileL)
			margin-bottom: 36px

	div.cards-shifter
		display: none
		align-items: center
		justify-content: space-between
		margin-top: 24px
		+max($mTabletB)
			display: flex

		span.current
			font-weight: 500
			color: $colorMain


	div.cards-outer-wrapper
		+max($mTabletB)
			overflow: hidden

		div.cards
			display: flex
			align-items: stretch
			justify-content: flex-start
			margin-right: -34px
			+max($mTabletB)
				+transition(transform)
				transform: translateX(calc((-100vw + 100px - 34px) * var(--shift)))
			+max($mMobileL)
				transform: translateX(calc((-100vw + 32px - 34px) * var(--shift)))

			div.u-card
				width: calc(100% / 3 - 34px)
				flex-grow: 0
				flex-shrink: 0
				+max($mTabletB)
					width: calc(100% - 34px)