@import "styles/vars"

section.pi-result
	div.inner-wrapper
		padding: 116px 50px
		display: flex
		align-items: flex-start
		justify-content: space-between
		+max($mTabletA)
			padding-left: 0
			padding-right: 0
			display: block
		+max($mMobileL)
			padding: 56px 0

		h2
			width: 25%
			+max($mTabletA)
				width: 100%

		div.content
			width: 75%
			+max($mTabletA)
				width: 100%

			div.results-wrapper
				div.result-item
					padding: 48px 0 24px 0
					display: flex
					align-items: flex-start
					justify-content: space-between
					border-bottom: 1px solid $colorDark
					+max($mMobileL)
						display: block
						padding: 32px 0 16px 0

					> *
						width: 50%
						flex-grow: 1
						flex-shrink: 1
						+max($mMobileL)
							width: 100%

					h3
						padding-right: 77px
						font-size: 36px
						line-height: 48px
						letter-spacing: -0.03em
						+max($mMobileL)
							font-size: 22px
							line-height: 120%
							margin-bottom: 24px

			div.metrics-wrapper
				margin-top: 32px
				padding: 32px
				background: $colorGrey
				border-radius: 24px
				+max($mMobileL)
					padding: 24px

				> p
					max-width: 548px

				div.metrics-list
					margin-top: 48px
					+childSpaceBottom(24px)
					+max($mMobileL)
						margin-top: 32px

					div.metric-item
						display: flex
						align-items: center
						justify-content: flex-start
						+max($mMobileL)
							flex-wrap: wrap
							justify-content: flex-end

						div.label, div.value
							flex-grow: 0
							flex-shrink: 0

						div.label
							width: 42%
							padding-right: 48px
							+max($mMobileL)
								padding-right: 0
								width: calc(50% + 12px)
								margin-right: 12px
								font-size: 16px
								letter-spacing: -0.03em

						div.value
							width: 28%
							font-size: 24px
							color: $colorMain
							font-weight: 500
							+max($mMobileL)
								width: calc(50% - 24px)
								font-size: 18px
								line-height: 28px

						div.measure
							flex-grow: 1
							flex-shrink: 1
							min-width: 0
							width: 100%
							+max($mMobileL)
								width: calc(50% - 24px)
								flex-grow: 0
								flex-shrink: 0


					