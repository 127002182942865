@font-face
	font-family: "Haas Grotesk Text"
	font-weight: 400
	src: url("/static/fonts/HaasGroteskText400.ttf") format("truetype")
	font-display: swap

@font-face
	font-family: "Haas Grotesk Text"
	font-weight: 500
	src: url("/static/fonts/HaasGroteskText500.ttf") format("truetype")
	font-display: swap