@import "styles/vars"

section.c-huge-text
	margin-top: 156px
	margin-bottom: 65px
	+max($mMobileL)
		margin-top: 96px
		margin-bottom: 56px

	div.inner-wrapper
		padding: 0 50px
		+max($mTabletA)
			padding: 0

	div.background
		background-image: url("/static/images/text-background.png")
		background-size: cover
		background-position: center
		mask-image: url("/static/images/text.svg")
		mask-size: contain

		img
			width: 100%
			opacity: 0