@import "styles/vars"

form.c-contact-form
	display: block
	padding: 32px
	background-color: $colorMain
	background-image: url("/static/images/topographic_small.svg")
	background-size: cover
	background-position: center
	color: white
	border-radius: 24px
	width: 605px
	+max($mTabletB)
		width: 100%
	+max($mMobileL)
		padding: 24px

	h2
		font-size: 36px
		line-height: 48px
		letter-spacing: -1.08px
		margin-bottom: 24px
		+max($mMobileL)
			font-size: 30px
			letter-spacing: -0.9px

	div.row
		margin-top: 16px
		&.double
			display: flex
			align-items: flex-start
			justify-content: space-between
			+childSpaceRight(30px)
			+max($mMobileL)
				display: block
				+childSpaceRight(0px)
				+childSpaceTop(16px)
			> *
				width: 50%
				flex-grow: 1
				flex-shrink: 1
				min-width: 0
				+max($mMobileL)
					width: 100%

	div.button-wrapper
		margin-top: 32px
		display: flex
		justify-content: flex-end