@import "styles/vars"

section.sp-how-it-works
	padding: 96px 0
	position: relative
	color: white
	+max($mMobileL)
		padding: 56px 0

	div.inner-wrapper
		padding: 0 50px
		+max($mTabletA)
			padding: 0

	&:before
		content: ""
		+pos(absolute, 0, -50px, -50px, 0)
		z-index: -1
		background-color: $colorMain
		background-image: url("/static/images/topographic.svg")
		background-size: cover
		background-position: center
		+max($mMobileL)
			left: -16px
			right: -16px

	div.hiw-content
		display: flex
		flex-wrap: wrap
		align-items: stretch
		justify-content: flex-start
		margin-top: 57px
		margin-right: -30px
		margin-bottom: -30px
		+max($mMobileL)
			margin-top: 32px
			margin-right: 0
			margin-bottom: -16px

		div.hiw-item
			width: calc(100% / 4 - 30px)
			margin-right: 30px
			margin-bottom: 30px
			flex-grow: 0
			flex-shrink: 1
			min-width: 0
			padding: 24px
			box-shadow: inset 0 0 0 1px white
			border-radius: 24px
			+transition(background, transform, box-shadow)
			+max($mTabletA)
				width: calc(100% / 2 - 30px)

			+min($mTabletA)
				&:not(.image)
					&:hover
						background: $colorMain
						transform: scale(1.08)
						box-shadow: none

			+max($mMobileL)
				margin-right: 0
				margin-bottom: 16px
				width: 100%

			div.count
				display: flex
				align-items: flex-start
				justify-content: flex-start

				span
					font-size: 16px
					line-height: 150%
					+max($mMobileL)
						font-size: 14px

					&.big
						font-size: 36px
						line-height: 120%
						letter-spacing: -1.08px
						margin-right: 6px
						+max($mMobileL)
							font-size: 30px
							letter-spacing: -0.9px

			header
				font-size: 36px
				line-height: 120%
				letter-spacing: -1.08px
				height: 1em * 1.2 * 3 // preserving 3 lines for header
				display: flex
				align-items: flex-end
				justify-content: flex-start
				margin-bottom: 16px
				+max($mMobileL)
					font-size: 30px
					height: auto
					margin-top: 30px
					letter-spacing: -0.9px

			p
				font-size: 16px
				line-height: 150%
				+max($mMobileL)
					font-size: 14px