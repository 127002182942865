@import "styles/vars"

main.v-projects-list
	div.inner-wrapper
		padding: 0 50px
		+max($mTabletA)
			padding: 0

	header
		padding: 80px 0px 48px 0px
		display: flex
		align-items: flex-end
		justify-content: flex-start
		border-bottom: 1px solid rgba($colorDark, .38)
		+max($mTabletA)
			display: block

		h1
			width: 50%
			+max($mTabletA)
				width: 100%

		p
			width: 50%
			max-width: 480px
			margin-left: 30px
			+max($mTabletA)
				width: 100%
				margin-left: 0
				margin-top: 30px

	div.projects-list
		padding: 48px 0 116px 0
		+childSpaceBottom(16px)

		article.project-card
			padding: 32px
			display: flex
			align-items: stretch
			justify-content: space-between
			border-radius: 16px
			background: $colorGrey
			+max($mTabletB)
				display: block
			+max($mMobileL)
				padding: 24px

			div.image
				width: 360px
				flex-grow: 0
				flex-shrink: 0
				margin-right: 32px
				+max($mTabletB)
					width: 100%
					margin-right: 0
					margin-bottom: 32px
				+max($mMobileL)
					margin-bottom: 25px

				div.aspect-ratio-wrapper
					width: 100%
					padding-top: calc(100% / 360 * 184)
					background: rgba($colorDark, .12)
					border-radius: 16px
					position: relative
					+max($mTabletA)
						padding-top: calc(100% / 360 * 240)
					+max($mTabletB)
						padding-top: calc(100% / 360 * 120)
					+max($mMobileL)
						padding-top: calc(100% / 360 * 200)

					img
						+pos(absolute, 0, 0, 0, 0)
						width: 100%
						height: 100%
						border-radius: 16px

			div.content
				flex-grow: 1
				flex-shrink: 1
				min-width: 0
				display: flex
				flex-direction: column
				align-items: flex-end
				justify-content: space-between
				+childSpaceBottom(16px)

				div.text
					h2
						font-size: 32px
						line-height: 120%
						margin-bottom: 16px
						letter-spacing: normal
						+max($mMobileL)
							font-size: 24px

				div.actions
					> *
						display: flex
						align-items: center
						justify-content: center
						text-align: center
						padding: 0 36px
						height: 50px
						border-radius: 25px
						background: $colorMain
						color: white
						box-shadow: inset 0 0 0 1px $colorMain
						+transition
						&:hover
							background: rgba($colorMain, .24)
							color: $colorMain

					