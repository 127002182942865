@import "styles/vars"

section.ap-values
	padding: 96px 0
	position: relative
	color: white
	+max($mMobileL)
		padding-top: 56px
		padding-bottom: 56px

	div.inner-wrapper
		padding: 0 50px
		+max($mTabletA)
			padding: 0

	&:before
		content: ""
		+pos(absolute, 0, -50px, -50px, 0)
		z-index: -1
		background-color: $colorMain
		background-image: url("/static/images/topographic.svg")
		background-size: cover
		background-position: center
		+max($mMobileL)
			left: -16px
			right: -16px

	header
		display: flex
		align-items: flex-end
		justify-content: space-between
		margin-top: 56px
		margin-bottom: 64px
		+max($mTabletA)
			display: block
		+max($mMobileL)
			margin-top: 32px
			margin-bottom: 36px

		h2
			max-width: 745px
			margin-right: 30px
			+max($mTabletA)
				margin-right: 0
				margin-bottom: 30px
				max-width: auto
			+max($mMobileL)
				margin-bottom: 16px

		p
			max-width: 370px

	div.autoscroller
		display: flex
		align-items: stretch
		justify-content: space-between
		+max($mTabletB)
			display: block

		div.sections
			width: 605px
			flex-grow: 0
			flex-shrink: 0
			+max($mTabletB)
				width: 100%

			div.item
				position: relative
				padding-left: 50px
				padding-right: 69px
				padding-bottom: 12px
				+max($mTabletB)
					padding-right: 0
				+max($mMobileL)
					padding-left: 28px

				&:before
					content: ""
					+pos(absolute, 0, 0, auto, 0)
					width: 1px
					background: rgba(white, .5)

				&:after
					content: ""
					width: 12px
					height: 12px
					+pos(absolute, 18px, -6px)
					background: url("/static/images/icons/sparkle_white.svg")
					transform-origin: 50% 50%
					transform: scale(1) translateZ(0)
					+transition(transform)
					+max($mMobileL)
						top: 9px

				&:first-child
					&:before
						top: 24px
						+max($mMobileL)
							top: 15px
				
				&:last-child
					&:before
						bottom: auto
						height: 24px
						+max($mMobileL)
							height: 15px

				&.active
					&:after
						transform: scale(1.5) translateZ(0)
					> i
						transform: rotateX(-180deg) translateZ(0)

				div.c-accordion-content
					transform: translateZ(0)
					will-change: height

				h3
					margin-bottom: 20px
					font-size: 36px
					line-height: 48px
					letter-spacing: -1.08px
					+max($mMobileL)
						font-size: 22px
						letter-spacing: -0.66px
						line-height: 30px

				> i
					display: none
					width: 24px
					height: 24px
					align-items: center
					justify-content: center
					pointer-events: none
					+transition(transform)
					+pos(absolute, 12px, auto, 0)
					+max($mTabletB)
						display: flex
					+max($mMobileL)
						top: 3px

				div.image
					display: none
					width: 100%
					position: relative
					+max($mTabletB)
						display: block
						padding-top: calc(100% * 180 / 315)
						margin-top: 30px
					+max($mMobileL)
						padding-top: calc(100% * 220 / 315)
						margin-top: 24px

					img
						+pos(absolute, 0, 0, 0, 0)
						width: 100%
						height: 100%
						border-radius: 16px

		div.image
			flex-grow: 1
			flex-shrink: 1
			border-radius: 24px
			background-color: $colorMain
			background-size: cover
			background-position: center
			+transition(background-image)
			+max($mTabletB)
				display: none