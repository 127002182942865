@import "styles/vars"
// 1340 x 505

section.hp-video
	margin-top: 72px
	+max($mMobileL)
		margin-top: 64px

	div.video-wrapper
		width: 100%
		padding-top: calc(100% * 505 / 1340)
		position: relative
		background: $colorDark
		overflow: hidden
		border-radius: 18px
		+max($mMobileL)
			padding-top: calc(100% * 196 / 343)

		video
			+pos(absolute, 0, 0, 0, 0)
			width: 100%
			height: 100%
			object-fit: cover
			border-radius: 18px