@import "styles/vars"

ul.c-solutions-list
	display: flex
	flex-direction: column
	align-items: flex-start
	justify-content: flex-start
	flex-wrap: wrap
	list-style: none
	max-height: calc((28px + 16px) * var(--count))
	+max($mMobileL)
		max-height: calc((18px + 12px) * var(--count))
		max-width: 100%
		margin-right: -16px

	li
		margin-bottom: 16px
		margin-right: 30px
		+max($mMobileL)
			margin-bottom: 12px
			margin-right: 16px
			font-size: 12px
			line-height: 150%
			min-width: 0
			max-width: 66%
			flex-shrink: 1
			+toe

		&:before
			content: "/"
			margin-right: 16px