@import "styles/vars"

div.c-simple-input
	> *
		display: block
		width: 100%

	label
		font-size: 16px
		line-height: 150%
		margin-bottom: 8px
		+max($mMobileL)
			font-size: 14px

	input, textarea
		font-size: 16px
		line-height: 150%
		color: $colorDark
		background: white
		+transition(filter)
		+placeholder
			color: rgba($colorDark, .54)
		+max($mMobileL)
			font-size: 14px
		&:read-only
			filter: brightness(.8)

	input
		height: 50px
		padding: 0 24px
		border-radius: 25px

	textarea
		padding: 16px
		height: 96px
		border-radius: 8px
		resize: none