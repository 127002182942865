@import "styles/vars"

section.ap-primary
	padding: 116px 0
	+max($mMobileL)
		padding-top: 56px
		padding-bottom: 64px

	div.inner-wrapper
		padding: 0 50px
		+max($mTabletA)
			padding: 0

	h1
		max-width: 1095px
		margin-bottom: 32px

	div.cta-wrapper
		display: flex
		+max($mMobileL)
			justify-content: flex-end