@import "styles/vars"

div.c-fancy-button
	height: 44px
	padding: 0 24px
	display: flex
	align-items: center
	justify-content: space-between
	border-radius: 22px
	+transition(background, box-shadow)
	&.hoverable
		cursor: pointer

	&.active
		font-weight: 500
		> img
			filter: invert(100%)

	&.light
		box-shadow: inset 0 0 0 1px white
		color: white
		&.hoverable:not(.active)
			&:hover
				background: rgba(white, .12)
		&.active
			background: white
			span.content
				color: black

	&.dark
		box-shadow: inset 0 0 0 1px $colorDark
		color: $colorDark
		&.active
			background: $colorMain
			color: white
			box-shadow: inset 0 0 0 1px $colorMain
			> img
				filter: invert(1) brightness(200%)

	span.content
		margin-right: 16px
		+transition(color)

	> img
		+transition(filter)