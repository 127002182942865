@import "styles/vars"

section.pi-solution
	+pos(relative, auto, -50px)
	width: calc(100% + 100px)
	background-color: $colorMain
	background-image: url("/static/images/topographic.svg")
	background-size: cover
	background-position: center
	+max($mMobileL)
		left: -16px
		width: calc(100% + 32px)

	div.inner-wrapper
		padding: 96px 50px
		+max($mMobileL)
			padding: 56px 16px

	div.steps
		display: flex
		align-items: stretch
		justify-content: space-between
		margin-top: 57px
		+childSpaceRight(30px)
		+max($mTabletB)
			+childSpaceRight(0)
			+childSpaceBottom(30px)
			display: block
		+max($mMobileL)
			margin-top: 32px
			+childSpaceBottom(16px)
			
		div.step
			box-shadow: inset 0 0 0 1px white
			padding: 32px
			width: 100%
			flex-grow: 1
			flex-shrink: 1
			min-width: 1
			color: white
			border-radius: 24px
			+max($mMobileL)
				padding: 24px

			div.numeration
				display: flex
				align-items: flex-start
				justify-content: flex-start

				div.number
					font-size: 36px
					line-height: 120%
					letter-spacing: -0.03em
					margin-right: 6px
					+max($mMobileL)
						font-size: 30px

				span.label
					font-size: 16px
					line-height: 24px

			h2
				font-size: 36px
				line-height: 120%
				letter-spacing: -0.03em
				margin-top: 48px
				padding-right: 48px
				+max($mTabletA)
					padding-right: 0
				+max($mMobileL)
					margin-top: 30px
					font-size: 30px

			p
				font-size: 16px
				line-height: 24px
				margin-top: 16px
				padding-right: 48px
				+max($mTabletA)
					padding-right: 0
				+max($mMobileL)
					font-size: 14px
					line-height: 22px

			ul
				margin-top: 48px
				padding-right: 48px
				+max($mTabletA)
					padding-right: 0
				+max($mMobileL)
					margin-top: 32px
				li
					font-size: 16px
					line-height: 28px
					+max($mMobileL)
						font-size: 14px
						line-height: 22px