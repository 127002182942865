@import "styles/vars"

.disabled > .c-generic-button
	cursor: not-allowed

	&.dark
		opacity: .38
		
	&.light
		opacity: .5

.c-generic-button
	height: 50px
	border-radius: 25px
	padding: 5px
	padding-right: 24px
	display: flex
	align-items: center
	justify-content: space-between
	cursor: pointer
	+transition(background, box-shadow, opacity)
	&.dark
		box-shadow: inset 0 0 0 1px $colorDark
		color: $colorDark

		*:not(.disabled) > &:hover
			background: $colorMain
			box-shadow: inset 0 0 0 1px $colorMain

			span.gb-content
				color: white

		span.arrow
			background: $colorMain
			color: white

	&.light
		box-shadow: inset 0 0 0 1px white
		color: white

		*:not(.disabled) > &:hover
			background: white

			span.gb-content
				color: $colorMain

		span.arrow
			background: white
			color: $colorMain

	> *
		pointer-events: none
		
	span.arrow
		margin-right: 14px
		width: 38px
		height: 38px
		display: flex
		align-items: center
		justify-content: center
		border-radius: 50%
		img
			width: 24px
			height: 24px

	span.gb-content
		+transition(color)