@import "styles/vars"

div.c-alert-modal-content
	width: 100%
	max-width: 665px
	padding: 56px 69px
	background-color: white
	background-image: url("/static/images/topographic_small_grey.svg")
	background-size: cover
	background-position: center
	border-radius: 24px
	+max($mMobileL)
		padding: 44px 16px

	h2
		font-size: 36px
		line-height: 48px
		letter-spacing: -1.08px
		margin-bottom: 24px
		text-align: center
		+max($mMobileL)
			font-size: 30px
			line-height: 120%
			letter-spacing: -0.9px

	p
		text-align: center

	div.actions
		margin-top: 36px
		display: flex
		align-items: center
		justify-content: center

		span.ok
			padding: 0 69px
			display: flex
			align-items: center
			justify-content: center
			height: 50px
			border-radius: 25px
			box-shadow: 0 0 0 1px $colorDark
			cursor: pointer
			+transition(color, background)
			&:hover
				color: white
				background: $colorDark