@import "styles/vars"

section.pi-info
	div.inner-wrapper
		padding: 72px 50px 96px 50px
		+max($mTabletA)
			padding-left: 0
			padding-right: 0
		+max($mMobileL)
			padding-top: 56px
			padding-bottom: 56px

	div.cards
		display: flex
		align-items: stretch
		justify-content: space-between
		+childSpaceRight(30px)
		+max($mTabletB)
			display: block
			+childSpaceRight(0)
			+childSpaceBottom(30px)

		.u-card
			width: 100%
			flex-grow: 1
			flex-shrink: 1
			min-width: 0

	div.counters
		margin-top: 56px
		display: flex
		align-items: flex-start
		justify-content: space-between
		+childSpaceRight(30px)
		+max($mTabletB)
			flex-wrap: wrap
			+childSpaceRight(0)
			+childSpaceBottom(30px)
			margin-right: -30px
		+max($mMobileL)
			margin-top: 32px
			margin-right: -16px
			+childSpaceBottom(24px)
		
		div.counter
			width: 100%
			flex-grow: 1
			flex-shrink: 1
			min-width: 0
			+childSpaceTop(16px)
			+max($mTabletB)
				margin-right: 30px !important
				width: calc(50% - 30px)
			+max($mMobileL)
				margin-right: 16px !important
				width: calc(50% - 16px)

			span
				opacity: .54

			p
				font-size: 22px
				letter-spacing: -0.03em
				+max($mMobileL)
					font-size: 16px