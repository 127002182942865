@import "styles/vars"

ol.c-breadcrumbs
	list-style: none
	display: flex
	flex-wrap: wrap
	align-items: center
	justify-self: flex-start
	+childSpaceRight(8px)

	li
		&:not(:last-child)
			&:after
				content: "/"
				margin-left: 8px
				opacity: .48

		a
			opacity: .48
			border-bottom: 1px solid transparent
			+transition
			&:hover
				opacity: 1
				color: $colorMain
				border-bottom-color: $colorMain
			
			&[aria-current="page"]
				opacity: 1
				pointer-events: none