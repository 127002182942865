@import "styles/vars"

section.hp-primary
	padding-top: 77px
	+max($mMobileL)
		padding-top: 80px

	div.text-content
		display: flex
		align-items: flex-end
		justify-content: space-between
		padding-right: 50px
		+max($mTabletA)
			display: block

		p
			width: 335px
			margin-left: 100px
			flex-shrink: 0
			+max($mTabletA)
				margin-left: 0
				margin-top: 20px

	div.actions
		display: flex
		margin-top: 32px
		+max($mMobileL)
			justify-content: flex-end