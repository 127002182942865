@keyframes fade-in
	0%
		opacity: 0
	100%
		opacity: 1

@keyframes fade-out
	0%
		opacity: 1
	100%
		opacity: 0

@keyframes scale-in
	0%
		transform: scale(0.8) translateZ(0)
	100%
		transform: scale(1) translateZ(0)

@keyframes scale-out
	0%
		transform: scale(1) translateZ(0)
	100%
		transform: scale(1.2) translateZ(0)