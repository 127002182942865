@import "styles/vars"

footer.c-footer
	padding: 96px 0
	position: relative
	+max($mMobileL)
		padding: 56px 0

	&:before
		content: ""
		+pos(absolute, 0, -50px, -50px, 0)
		z-index: -1
		background: $colorGrey
		+max($mMobileL)
			left: -16px
			right: -16px

	div.inner-wrapper
		padding: 0 50px
		display: flex
		align-items: flex-start
		justify-content: space-between
		+max($mTabletA)
			padding: 0
		+max($mMobileL)
			display: block

		div.left
			+max($mMobileL)
				margin-bottom: 64px

			img
				+max($mMobileL)
					margin: 0 auto

			div.company-info
				margin-top: 27px
				margin-bottom: 24px
				font-size: 16px
				+max($mMobileL)
					font-size: 14px
					line-height: 28px
					margin-top: 24px
					text-align: center

			div.socials
				display: flex
				align-items: center
				justify-content: flex-start
				+childSpaceRight(16px)
				+max($mMobileL)
					justify-content: center

				a
					&:hover
						img
							filter: none
					img
						width: 32px
						filter: brightness(0)
						+transition(filter)

		div.right
			width: 50%
			display: flex
			align-items: flex-start
			justify-content: space-around
			+childSpaceRight(30px)
			margin-left: 30px
			+max($mTabletB)
				width: auto
				flex-shrink: 0
			+max($mMobileL)
				margin-left: 0
				justify-content: flex-start

			div.column
				header
					font-size: 24px
					line-height: 36px
					margin-bottom: 24px
					+max($mMobileL)
						font-size: 22px

				ul
					list-style: none
					font-size: 16px
					+childSpaceBottom(8px)

					li
						&.disabled
							> *
								cursor: not-allowed
								filter: blur(1px)
								opacity: .38

					a
						+transition(color, border-color)
						border-bottom: 1px solid transparent
						+max($mMobileL)
							font-size: 13px
							line-height: 28px
						&:hover
							color: $colorMain
							border-color: $colorMain

div.c-footerline
	position: relative
	color: white
	padding: 4px 0
	+max($mMobileL)
		padding: 16px 0

	&:before
		content: ""
		+pos(absolute, 0, -50px, -50px, 0)
		z-index: -1
		background: $colorDark
		+max($mMobileL)
			left: -16px
			right: -16px

	div.inner-wrapper
		padding: 0 50px
		display: flex
		align-items: center
		justify-content: space-between
		font-size: 12px
		+max($mTabletA)
			padding: 0

		div.links
			display: flex
			align-items: center
			justify-content: flex-end
			+childSpaceRight(36px)

			a
				text-decoration: underline
				+transition(color)
				&:hover
					color: $colorMain