@import "styles/vars"

main.v-project-item
	header
		padding: 48px 50px 72px 50px
		+max($mTabletA)
			padding-left: 0
			padding-right: 0
		+max($mMobileL)
			padding-top: 36px
			padding-bottom: 64px

		div.content-wrapper
			display: flex
			align-items: flex-start
			justify-content: space-between
			margin-top: 56px
			+max($mTabletA)
				display: block

			> *
				width: 50%
				min-width: 0
				flex-grow: 1
				flex-shrink: 1
				+max($mTabletA)
					width: 100%

			div.counters
				display: flex
				flex-wrap: wrap
				align-items: flex-start
				justify-content: space-between
				+childSpaceBottom(30px)
				+max($mTabletA)
					margin-top: 48px
				+max($mMobileS)
					display: block

				div.counter
					width: 50%
					+max($mMobileS)
						width: 100%

					span
						font-size: 36px
						line-height: 48px
						letter-spacing: -0.03em
						white-space: nowrap
						+max($mMobileL)
							font-size: 30px

					p
						margin-top: 12px

	div.pi-image
		height: 400px
		+pos(relative, auto, -50px)
		width: calc(100% + 100px)
		background-color: $colorGrey
		background-size: cover
		background-position: center
		+max($mMobileL)
			left: -16px
			width: calc(100% + 32px)
			height: 140px