@import "styles/vars"

section.cp-form
	padding: 116px 0 20px 0
	+max($mMobileL)
		padding-top: 56px

	div.inner-wrapper
		padding: 0 50px
		display: flex
		align-items: flex-start
		justify-content: space-between
		+max($mTabletA)
			padding: 0
			display: block

		div.left
			flex-grow: 1
			flex-shrink: 1
			min-width: 0
			margin-right: 36px
			+max($mTabletA)
				display: flex
				align-items: flex-start
				justify-content: space-between
				margin-bottom: 74px
			+max($mTabletB)
				display: block

			header
				flex-shrink: 0
				+max($mTabletA)
					margin-right: 36px
				> p
					margin-top: 16px

			div.contacts
				margin-top: 74px
				display: flex
				align-items: flex-start
				justify-content: flex-start
				+childSpaceRight(30px)
				+max($mTabletA)
					margin-top: 28px
				+max($mTabletB)
					margin-top: 48px
				+max($mMobileL)
					display: block
					+childSpaceRight(0)
					+childSpaceBottom(24px)

				div.column
					+childSpaceBottom(24px)
					min-width: 0
					max-width: 290px
					&.noshrink
						flex-shrink: 0

				div.contact-item
					display: flex
					align-items: flex-start
					justify-content: flex-start

					img
						flex-grow: 0
						flex-shrink: 0
						width: 24px
						height: 24px
						margin-right: 14px

					div.content
						div.caption
							line-height: 24px
							font-size: 18px
							font-weight: 500

						div.description
							font-size: 16px
							line-height: 28px
							margin-top: 12px

		div.right
			flex-grow: 0
			flex-shrink: 0
			+max($mTabletA)
				form.c-contact-form
					margin: 0 auto