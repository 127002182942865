@import "styles/vars"

div.c-accordion
	border-bottom: 1px solid rgba($colorDark, .38)
	&.expanded
		header
			i
				transform: rotateX(-180deg)

	header
		display: flex
		align-items: center
		justify-content: space-between
		cursor: pointer
		user-select: none
		i
			+transition(transform)
			margin-left: 24px

	div.ac-content-wrapper
		margin-bottom: 24px
		overflow: hidden
		+max($mMobileL)
			margin-bottom: 20px

		div.ac-content
			padding-top: 24px
			+max($mMobileL)
				padding-top: 20px
