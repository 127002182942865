@import "styles/vars"

section.c-about
	padding: 116px 50px
	+max($mTabletA)
		padding: 116px 0
	+max($mMobileL)
		padding: 56px 0 72px 0

	div.text-content
		display: flex
		align-items: flex-end
		justify-content: space-between
		margin-top: 56px
		&:first-child
			margin-top: 0 !important

		+max($mTabletA)
			display: block

		+max($mMobileL)
			margin-top: 32px

		h2
			max-width: 720px
			margin-right: 36px
			+max($mTabletA)
				margin-right: 0
				margin-bottom: 36px
			+max($mMobileL)
				margin-bottom: 16px

		p
			max-width: 370px

	div.counters
		margin-top: 110px
		display: flex
		align-items: center
		justify-content: space-between
		+max($mTabletA)
			flex-wrap: wrap
			margin-bottom: -48px
		+max($mMobileL)
			margin-top: 48px
			align-items: flex-start

		div.counter-item
			+max($mTabletA)
				width: 50%
				margin-bottom: 48px
			div.number
				font-size: 120px
				line-height: 120px
				letter-spacing: -3.6px
				color: $colorMain
				margin-bottom: 21px
				+max($mTabletB)
					font-size: 84px
					line-height: 84px
				+max($mMobileL)
					font-size: 56px
					line-height: 120%
					letter-spacing: -1.68px
					margin-bottom: 8px

			p.label
				font-size: 20px
				line-height: 28px
				+max($mMobileL)
					font-size: 14px
					line-height: 150%