@import "styles/vars"

section.sp-primary
	padding: 81px 0 116px 0
	+max($mMobileL)
		padding-top: 56px
		padding-bottom: 72px

	div.inner-wrapper
		padding: 0 50px
		+max($mTabletA)
			padding: 0

	header
		padding-bottom: 48px
		border-bottom: 1px solid rgba($colorDark, .54)
		margin-bottom: 48px
		display: flex
		align-items: flex-end
		justify-content: flex-start
		+max($mMobileL)
			display: block
			padding-bottom: 32px
			margin-bottom: 32px

		h1
			margin-right: 49px
			max-width: 585px
			+max($mMobileL)
				margin-right: 0
				margin-bottom: 16px
		
		p
			max-width: 420px

	div.services-list
		display: flex
		flex-wrap: wrap
		flex-shrink: 0
		+childSpaceRight(8px)
		+childSpaceBottom(16px)
		+max($mMobileL)
			+childSpaceRight(0px)
			+childSpaceBottom(12px)
			flex-direction: column
			align-items: center
			justify-content: center

	div.selected-service-content
		padding-top: 56px
		+max($mMobileL)
			padding-top: 64px

		h2
			font-size: 36px
			line-height: 48px
			letter-spacing: -1.08px
			margin-bottom: 20px

		div.content
			display: flex
			align-items: flex-start
			justify-content: space-between
			+max($mTabletB)
				display: block

			p
				max-width: 555px
				margin-right: 79px
				min-width: 0
				flex-grow: 1
				flex-shrink: 1
				+max($mTabletB)
					max-width: 100%
					margin-right: 0
					margin-bottom: 30px

			ul
				flex-grow: 1
				flex-shrink: 0